/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiBarChartLine } from "react-icons/ri"

const EngagementActionPlanDownloadPage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Employee Engagement Action Plan Template | Strategy Implementation Tool"
        description="Download our engagement action plan template to translate survey results into meaningful workplace improvements. Includes priority setting and impact tracking."
        keywords={[
          "engagement action plan",
          "employee feedback implementation",
          "workplace improvement strategy",
          "engagement survey follow-up",
          "HR improvement template",
          "employee experience enhancement",
          "engagement initiative planning",
          "feedback action template",
          "organizational development plan",
          "employee satisfaction strategy"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-engagement" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Engagement Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Engagement Action Plan Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A structured framework for turning engagement survey insights into meaningful actions.
              This template helps prioritize initiatives, assign ownership, and track progress to 
              create measurable improvements in employee engagement.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <a 
                href="/templates/engagement-action-plan.pdf" 
                download
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Download PDF
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Template Preview Section */}
      <div sx={{
        bg: 'white',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1000px',
          mx: 'auto',
          px: 3,
        }}>
          <div className="printable-template" sx={{
            border: '1px solid',
            borderColor: 'rgba(0,0,0,0.1)',
            borderRadius: '8px',
            p: [3, 4],
            mb: 5,
            boxShadow: '0 2px 15px rgba(0,0,0,0.05)',
            '@media print': {
              border: 'none',
              boxShadow: 'none',
              p: 0
            }
          }}>
            {/* Template Header */}
            <div sx={{
              borderBottom: '2px solid',
              borderColor: 'primary',
              pb: 3,
              mb: 4,
              '@media print': {
                pb: 2,
                mb: 3
              }
            }}>
              <h2 sx={{
                fontSize: ['1.5rem', '1.8rem'],
                fontWeight: 700,
                color: 'primary',
                textAlign: 'center',
                mb: 2
              }}>
                Employee Engagement Action Plan
              </h2>
              
              <p sx={{
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8,
                textAlign: 'center',
                mb: 3
              }}>
                Use this framework to develop targeted initiatives that address engagement survey findings
                and create meaningful improvements in employee experience.
              </p>
              
              <div sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: 2,
                borderTop: '1px solid',
                borderColor: 'rgba(0,0,0,0.1)',
                pt: 2,
                fontSize: '0.8rem',
                color: 'text',
                '@media print': {
                  pt: 1
                }
              }}>
                <div>
                  <strong>Department/Team:</strong> ____________________
                </div>
                <div>
                  <strong>Plan Date:</strong> ____________________
                </div>
                <div>
                  <strong>Review Date:</strong> ____________________
                </div>
              </div>
            </div>
            
            {/* Summary Section */}
            <div sx={{
              mb: 4,
              '@media print': {
                mb: 3
              }
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Engagement Survey Summary
              </h3>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 3,
                mb: 3
              }}>
                <div>
                  <label 
                    htmlFor="survey-date" 
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.9rem', 
                      fontWeight: 600, 
                      mb: 1 
                    }}
                  >
                    Survey Date
                  </label>
                  <div 
                    id="survey-date"
                    sx={{ 
                      border: '1px solid', 
                      borderColor: 'rgba(0,0,0,0.2)', 
                      p: 2, 
                      borderRadius: '4px',
                      height: '40px'
                    }}
                  />
                </div>
                
                <div>
                  <label 
                    htmlFor="response-rate" 
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.9rem', 
                      fontWeight: 600, 
                      mb: 1 
                    }}
                  >
                    Response Rate
                  </label>
                  <div 
                    id="response-rate"
                    sx={{ 
                      border: '1px solid', 
                      borderColor: 'rgba(0,0,0,0.2)', 
                      p: 2, 
                      borderRadius: '4px',
                      height: '40px'
                    }}
                  />
                </div>
              </div>
              
              <div sx={{ mb: 3 }}>
                <label 
                  htmlFor="overall-score" 
                  sx={{ 
                    display: 'block', 
                    fontSize: '0.9rem', 
                    fontWeight: 600, 
                    mb: 1 
                  }}
                >
                  Overall Engagement Score
                </label>
                <div 
                  id="overall-score"
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid', 
                    borderColor: 'rgba(0,0,0,0.2)', 
                    p: 2, 
                    borderRadius: '4px',
                    height: '60px',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: 'primary'
                  }}
                >
                  ___ / 5
                </div>
              </div>
              
              <div>
                <label 
                  htmlFor="key-findings" 
                  sx={{ 
                    display: 'block', 
                    fontSize: '0.9rem', 
                    fontWeight: 600, 
                    mb: 1 
                  }}
                >
                  Key Findings Summary
                </label>
                <div 
                  id="key-findings"
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'rgba(0,0,0,0.2)', 
                    p: 2, 
                    borderRadius: '4px',
                    minHeight: '80px'
                  }}
                />
              </div>
            </div>
            
            {/* Strengths & Opportunities */}
            <div sx={{
              mb: 4,
              '@media print': {
                mb: 3,
                pageBreakInside: 'avoid'
              }
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 600,
                color: 'primary',
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiBarChartLine /> Strengths & Opportunities
              </h3>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 4,
                mb: 3
              }}>
                <div>
                  <label 
                    htmlFor="strengths" 
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.9rem', 
                      fontWeight: 600, 
                      mb: 2,
                      color: 'green'
                    }}
                  >
                    Top Strengths (Highest Scoring Areas)
                  </label>
                  <div 
                    id="strengths"
                    sx={{ 
                      border: '1px solid', 
                      borderColor: 'rgba(0,0,0,0.2)', 
                      borderRadius: '4px',
                      overflow: 'hidden'
                    }}
                  >
                    {[1, 2, 3].map(num => (
                      <div 
                        key={`strength-${num}`}
                        sx={{
                          p: 2,
                          borderBottom: num < 3 ? '1px solid' : 'none',
                          borderColor: 'rgba(0,0,0,0.1)'
                        }}
                      >
                        {num}. ___________________________________
                      </div>
                    ))}
                  </div>
                </div>
                
                <div>
                  <label 
                    htmlFor="opportunities" 
                    sx={{ 
                      display: 'block', 
                      fontSize: '0.9rem', 
                      fontWeight: 600, 
                      mb: 2,
                      color: 'orange'
                    }}
                  >
                    Key Opportunities (Lowest Scoring Areas)
                  </label>
                  <div 
                    id="opportunities"
                    sx={{ 
                      border: '1px solid', 
                      borderColor: 'rgba(0,0,0,0.2)', 
                      borderRadius: '4px',
                      overflow: 'hidden'
                    }}
                  >
                    {[1, 2, 3].map(num => (
                      <div 
                        key={`opportunity-${num}`}
                        sx={{
                          p: 2,
                          borderBottom: num < 3 ? '1px solid' : 'none',
                          borderColor: 'rgba(0,0,0,0.1)'
                        }}
                      >
                        {num}. ___________________________________
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            
            {/* Action Plan */}
            <div sx={{
              mb: 4,
              '@media print': {
                mb: 3,
                pageBreakInside: 'avoid'
              }
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Priority Action Items
              </h3>
              
              {[1, 2, 3].map(actionNum => (
                <div 
                  key={`action-${actionNum}`}
                  sx={{
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    borderRadius: '6px',
                    p: 3,
                    mb: 3,
                    bg: 'rgba(0,0,0,0.01)'
                  }}
                >
                  <div sx={{ 
                    mb: 2,
                    pb: 1,
                    borderBottom: '1px dashed',
                    borderColor: 'rgba(0,0,0,0.1)',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    color: 'primary'
                  }}>
                    Action Item #{actionNum}
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    gap: 3,
                    mb: 2
                  }}>
                    <div>
                      <label 
                        htmlFor={`focus-area-${actionNum}`} 
                        sx={{ 
                          display: 'block', 
                          fontSize: '0.9rem', 
                          fontWeight: 600, 
                          mb: 1 
                        }}
                      >
                        Focus Area/Engagement Driver
                      </label>
                      <div 
                        id={`focus-area-${actionNum}`}
                        sx={{ 
                          border: '1px solid', 
                          borderColor: 'rgba(0,0,0,0.2)', 
                          p: 2, 
                          borderRadius: '4px',
                          minHeight: '40px'
                        }}
                      />
                    </div>
                    
                    <div>
                      <label 
                        htmlFor={`priority-${actionNum}`} 
                        sx={{ 
                          display: 'block', 
                          fontSize: '0.9rem', 
                          fontWeight: 600, 
                          mb: 1 
                        }}
                      >
                        Priority Level
                      </label>
                      <div 
                        id={`priority-${actionNum}`}
                        sx={{ 
                          border: '1px solid', 
                          borderColor: 'rgba(0,0,0,0.2)', 
                          p: 2, 
                          borderRadius: '4px',
                          minHeight: '40px'
                        }}
                      >
                        □ High   □ Medium   □ Low
                      </div>
                    </div>
                  </div>
                  
                  <div sx={{ mb: 2 }}>
                    <label 
                      htmlFor={`objective-${actionNum}`} 
                      sx={{ 
                        display: 'block', 
                        fontSize: '0.9rem', 
                        fontWeight: 600, 
                        mb: 1 
                      }}
                    >
                      Objective/Desired Outcome
                    </label>
                    <div 
                      id={`objective-${actionNum}`}
                      sx={{ 
                        border: '1px solid', 
                        borderColor: 'rgba(0,0,0,0.2)', 
                        p: 2, 
                        borderRadius: '4px',
                        minHeight: '60px'
                      }}
                    />
                  </div>
                  
                  <div sx={{ mb: 2 }}>
                    <label 
                      htmlFor={`specific-actions-${actionNum}`} 
                      sx={{ 
                        display: 'block', 
                        fontSize: '0.9rem', 
                        fontWeight: 600, 
                        mb: 1 
                      }}
                    >
                      Specific Actions
                    </label>
                    <div 
                      id={`specific-actions-${actionNum}`}
                      sx={{ 
                        border: '1px solid', 
                        borderColor: 'rgba(0,0,0,0.2)', 
                        borderRadius: '4px',
                        overflow: 'hidden'
                      }}
                    >
                      {[1, 2, 3].map(stepNum => (
                        <div 
                          key={`action-${actionNum}-step-${stepNum}`}
                          sx={{
                            p: 2,
                            borderBottom: stepNum < 3 ? '1px solid' : 'none',
                            borderColor: 'rgba(0,0,0,0.1)'
                          }}
                        >
                          {stepNum}. ___________________________________
                        </div>
                      ))}
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    gap: 3,
                    mb: 2
                  }}>
                    <div>
                      <label 
                        htmlFor={`owner-${actionNum}`} 
                        sx={{ 
                          display: 'block', 
                          fontSize: '0.9rem', 
                          fontWeight: 600, 
                          mb: 1 
                        }}
                      >
                        Owner/Responsible
                      </label>
                      <div 
                        id={`owner-${actionNum}`}
                        sx={{ 
                          border: '1px solid', 
                          borderColor: 'rgba(0,0,0,0.2)', 
                          p: 2, 
                          borderRadius: '4px',
                          minHeight: '40px'
                        }}
                      />
                    </div>
                    
                    <div>
                      <label 
                        htmlFor={`timeline-${actionNum}`} 
                        sx={{ 
                          display: 'block', 
                          fontSize: '0.9rem', 
                          fontWeight: 600, 
                          mb: 1 
                        }}
                      >
                        Timeline
                      </label>
                      <div 
                        id={`timeline-${actionNum}`}
                        sx={{ 
                          border: '1px solid', 
                          borderColor: 'rgba(0,0,0,0.2)', 
                          p: 2, 
                          borderRadius: '4px',
                          minHeight: '40px'
                        }}
                      />
                    </div>
                  </div>
                  
                  <div>
                    <label 
                      htmlFor={`success-measures-${actionNum}`} 
                      sx={{ 
                        display: 'block', 
                        fontSize: '0.9rem', 
                        fontWeight: 600, 
                        mb: 1 
                      }}
                    >
                      Success Measures
                    </label>
                    <div 
                      id={`success-measures-${actionNum}`}
                      sx={{ 
                        border: '1px solid', 
                        borderColor: 'rgba(0,0,0,0.2)', 
                        p: 2, 
                        borderRadius: '4px',
                        minHeight: '60px'
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            
            {/* Communication Plan */}
            <div sx={{
              mb: 4,
              '@media print': {
                mb: 3,
                pageBreakInside: 'avoid'
              }
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Communication Plan
              </h3>
              
              <div sx={{ mb: 3 }}>
                <label 
                  htmlFor="survey-results" 
                  sx={{ 
                    display: 'block', 
                    fontSize: '0.9rem', 
                    fontWeight: 600, 
                    mb: 1 
                  }}
                >
                  How will survey results be shared with employees?
                </label>
                <div 
                  id="survey-results"
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'rgba(0,0,0,0.2)', 
                    p: 2, 
                    borderRadius: '4px',
                    minHeight: '60px'
                  }}
                />
              </div>
              
              <div sx={{ mb: 3 }}>
                <label 
                  htmlFor="action-plan-communication" 
                  sx={{ 
                    display: 'block', 
                    fontSize: '0.9rem', 
                    fontWeight: 600, 
                    mb: 1 
                  }}
                >
                  How will this action plan be communicated?
                </label>
                <div 
                  id="action-plan-communication"
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'rgba(0,0,0,0.2)', 
                    p: 2, 
                    borderRadius: '4px',
                    minHeight: '60px'
                  }}
                />
              </div>
              
              <div>
                <label 
                  htmlFor="progress-updates" 
                  sx={{ 
                    display: 'block', 
                    fontSize: '0.9rem', 
                    fontWeight: 600, 
                    mb: 1 
                  }}
                >
                  How will progress updates be shared?
                </label>
                <div 
                  id="progress-updates"
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'rgba(0,0,0,0.2)', 
                    p: 2, 
                    borderRadius: '4px',
                    minHeight: '60px'
                  }}
                />
              </div>
            </div>
            
            {/* Footer */}
            <div sx={{
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)',
              textAlign: 'center',
              fontSize: '0.8rem',
              color: 'text',
              opacity: 0.7,
              '@media print': {
                mt: 3,
                pt: 2
              }
            }}>
              Regular review of this action plan is recommended to track progress and make adjustments as needed.
            </div>
          </div>
          
          <div sx={{
            display: ['block', 'flex'],
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media print': {
              display: 'none'
            }
          }}>
            <div sx={{
              mb: [3, 0]
            }}>
              <h3 sx={{
                fontSize: '1.2rem',
                fontWeight: 700,
                color: 'text',
                mb: 1
              }}>
                How to use this template
              </h3>
              
              <ul sx={{
                pl: 3,
                fontSize: '0.95rem'
              }}>
                <li sx={{ mb: 1 }}>Complete this plan within 2-3 weeks of receiving survey results</li>
                <li sx={{ mb: 1 }}>Focus on 2-3 key priorities rather than trying to fix everything at once</li>
                <li sx={{ mb: 1 }}>Involve employees in developing action items for greater buy-in</li>
                <li sx={{ mb: 1 }}>Communicate both the plan and regular progress updates</li>
                <li sx={{ mb: 1 }}>Review progress monthly and adjust tactics as needed</li>
              </ul>
            </div>
            
            <div sx={{
              textAlign: ['left', 'right']
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease',
                  mb: 2,
                  '&:hover': {
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <p sx={{
                fontSize: '0.8rem',
                color: 'text',
                opacity: 0.7
              }}>
                For more HR templates and tools, <Link to="/guides/employee-engagement" sx={{ color: 'primary' }}>visit our guide</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EngagementActionPlanDownloadPage 